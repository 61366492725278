import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { VerifyComponent } from './login/verify/verify.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./business-list/business-list.module').then(m => m.BusinessListModule), pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'verify', component: VerifyComponent },
  { path: 'location', loadChildren: () => import('./location/location.module').then(m => m.LocationModule) },
  { path: 'languages', loadChildren: () => import('./languages/languages.module').then(m => m.LanguagesModule) },
  { path: 'page', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
  { path: 'business', loadChildren: () => import('./business/business.module').then(m => m.BusinessModule) },
  { path: 'getstarted', loadChildren: () => import('./getstared/getstared.module').then(m => m.GetstaredModule) },

  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
