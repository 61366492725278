
export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBUNAREBFADFQQLEV6eMJPZ1vAtgjALFYU',
    authDomain: 'towneshopcom.firebaseapp.com',
    databaseURL: 'https://towneshopcom.firebaseio.com',
    projectId: 'towneshopcom',
    storageBucket: 'towneshopcom.appspot.com',
    messagingSenderId: '180130538949',
    appId: '1:180130538949:web:a17f1766c532a1437a17ac',
    measurementId: 'G-H6FVBF39VL'

  },
  functionUrl: 'https://us-central1-towneshopcom.cloudfunctions.net',
  functionsRegionToken: 'us-central1',
  googleMapsKey: 'AIzaSyA3A3a2-XwVVn9z9BMnf7PQwJtPaVDTUPU',
};
