import { Component, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// import { AuthService } from './core/auth.service';
// import { Plugins, PushNotification, PushNotificationToken, PushNotificationActionPerformed } from '@capacitor/core';
import { ActionPerformed, PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';
import { Router, ActivationEnd, Scroll, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationCancel, NavigationEnd, RoutesRecognized } from '@angular/router';
import { Device } from '@capacitor/device';
import { App, } from '@capacitor/app';
import { Dialog } from '@capacitor/dialog';
import { filter, map, pairwise } from 'rxjs/operators';
// import { AngularFirestore } from '@angular/fire/firestore';
// import { Subscription } from 'rxjs';
import { AppService } from './core/app.service';
import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links/ngx';
import { ViewportScroller } from '@angular/common';


// const { PushNotifications, Modals, App, Device } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  referral: string;
  title = 'towneshop';
  loadingRouteConfig: boolean;


  constructor(private translate: TranslateService, private zone: NgZone, private firebaseDynamicLinks: FirebaseDynamicLinks,
    private router: Router, private app: AppService, private viewportScroller: ViewportScroller) {

    this.initializeLocalStorage();
    this.language();
    this.scrollRestoreDynamicContent();
    this.initializeCapacitorpp();
    this.lazyLoader();

    if (location.pathname === '/' && localStorage.getItem('router')) {
      console.log(localStorage.getItem('router'));
      this.router.navigateByUrl(localStorage.getItem('router'));
      return;
    }

  }


  initializeCapacitorpp() {
    Device.getInfo().then(res => {
      if (res.platform === 'android') {
        this.pushNotification();
        this.dynamicLinks();
        this.deepLink();
        this.backExit();
      }
    });
  }

  lazyLoader() {
    this.router.events.subscribe(e => {
      if (e instanceof RouteConfigLoadStart) {
        this.loadingRouteConfig = true;
      } else if (e instanceof RouteConfigLoadEnd) {
        this.loadingRouteConfig = false;
      } else if (e instanceof NavigationCancel) {
        this.loadingRouteConfig = false
      }
    });
  }

  initializeLocalStorage() {
    this.router.events.pipe(filter(e => (e instanceof ActivationEnd) && (Object.keys(e.snapshot.queryParams).length > 0)),
      map(e => e instanceof ActivationEnd ? e.snapshot.queryParams : {})
    ).subscribe(queryParams => {
      if (queryParams.referral) {
        localStorage.setItem('referral', queryParams.referral);
      }
      if (queryParams.lang) {
        if (this.translate.getLangs()?.includes(queryParams.lang)) {
          this.translate.setDefaultLang(queryParams.lang);
          this.translate.use(queryParams.lang);
          localStorage.setItem('language', queryParams.lang);
        }
      }
    });


    localStorage.setItem('prev_host', document.referrer.split('/')[2]);

    this.router.events.pipe(filter((e: any) => e instanceof RoutesRecognized), pairwise())
      .subscribe((e: any) => {
        // console.log(e); // previous url
        if (e[0].urlAfterRedirects) {
          // console.log(e[0].urlAfterRedirects);
          localStorage.setItem('prev_host', location.host);
          // console.log(localStorage.getItem('prev_host'));

        }
      });



  }

  scrollRestoreDynamicContent() {
    this.router.events.pipe(filter(e => e instanceof Scroll)).subscribe((e: any) => {
      // console.log(e);
      setTimeout(() => {
        if (e?.position) {
          this.viewportScroller.scrollToPosition(e.position);
        } else if (e?.anchor) {
          this.viewportScroller.scrollToAnchor(e.anchor);
        } else {
          this.viewportScroller?.scrollToPosition([0, 0]);
        }
      });
    });
  }

  backExit() {
    App.addListener('backButton', (data: any) => {
      this.zone.run(() => {
        // console.log('data', data);
        // console.log(location.href);
        if (this.router.url === '/') {
          App.exitApp();
        } else {
          this.app.back();
        }
      });
    });
  }

  deepLink() {
    App.addListener('appUrlOpen', (data: any) => {
      this.zone.run(() => {
        let slug = data.url.split(".app").pop();
        if (slug && slug?.startsWith('https://towneshop.com')) {
          slug = slug.replace('https://towneshop.com', '');
          this.router.navigateByUrl(slug);
          return;
        }
      });
    });
  }

  language() {
    this.translate.addLangs(['en', 'hi', 'mr', 'ma', 'gu', 'ba', 'ta', 'pu', 'ur']);
    if (this.translate.getLangs()?.includes(localStorage.getItem('language'))) {
      this.translate.setDefaultLang(localStorage.getItem('language'));
      this.translate.use(localStorage.getItem('language'));
    } else {
      this.translate.setDefaultLang('en');
      this.translate.use('en');
      localStorage.setItem('language', 'en');
    }
  }

  dynamicLinks() {
    this.firebaseDynamicLinks.onDynamicLink().subscribe((res: any) => {
      let deepLink = res.deepLink || '';
      if (deepLink && deepLink?.startsWith('https://towneshop.com')) {
        deepLink = deepLink.replace('https://towneshop.com', '');
        this.router.navigateByUrl(deepLink);
        return;
      }

    }, (error: any) => {
      console.log(error);
    });

  }

  pushNotification() {

    //////////////moved some part to home html due to performance


    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      var audio1 = new Audio('assets/audio.mp3');
      audio1.play();
      Dialog.alert({
        title: notification.title,
        message: notification.body
      });

    });

    PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
      this.zone.run(() => {
        this.router.navigateByUrl(notification?.notification?.data?.link || '/');
        return;
      });
    });
  }





}