// improved
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { Observable, interval } from 'rxjs';
import { AuthService } from '../../core/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {
  model: any = {};
  returnUrl: string;
  finalPhone: string;
  phone: string;
  loading = false;
  counter: Observable<any>;
  dialCode: string;
  verify: any;
  constructor(public router: Router, public route: ActivatedRoute, public translate: TranslateService,
    private http: HttpClient, private auth: AuthService, public snackBar: MatSnackBar) {
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '';
    this.dialCode = this.route.snapshot.queryParamMap.get('dialCode');
    this.phone = this.route.snapshot.paramMap.get('phone');
  }

  ngOnInit(): void {
    this.verify = JSON.parse(localStorage.getItem('verificationId') || '{}');
    this.phone = localStorage.getItem('phone');
    this.dialCode = localStorage.getItem('dialCode');
  }



  verifyOtp(model) {
    this.loading = true;

    this.counter = interval(1000).pipe(
      map((x) => {
        if (x <= 60) {
          const res = 60 - x;
          return "0:" + res;
        } else {
          return;
        }
      }));
    return this.auth.verifyOtp(this.verify, model.otp, this.returnUrl).then(res => {
      this.loading = false;
    }).catch(err => {
      this.loading = false;
    });;
  }


}
