import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private router: Router, private location: Location) { }


  public back(fallbackPath?: string) {

    // console.log(localStorage.getItem('prev_host'));

    if (localStorage.getItem('prev_host') !== location.host) {
      // console.log('document_referrer');
      if (fallbackPath) {
        this.router.navigate([fallbackPath], { replaceUrl: true });
      } else {
        this.router.navigate(['/']);
      }
      return;
    }
    // console.log('normal');


    const prevPage = location.href;
    this.location.back();
    setTimeout(() => {
      if (location.href == prevPage) {
        if (fallbackPath) {
          this.router.navigate([fallbackPath], { replaceUrl: true });
        } else {
          this.router.navigate(['/']);
        }
      }
    }, 200);
  }
}
