import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links/ngx';

import { environment } from 'src/environments/environment';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { provideAuth, getAuth } from '@angular/fire/auth';
// import { AngularFireModule } from '@angular/fire';
// import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { AngularFireStorageModule, BUCKET } from '@angular/fire/storage';

import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './core/reuse-strategy';
import { MyErrorHandler } from './core/my-error-handler';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService, } from '@angular/fire/analytics';
import { MatSnackBarModule } from '@angular/material/snack-bar';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideStorage(() => getStorage()),
    provideAnalytics(() => getAnalytics()),
    MatSnackBarModule,
    MatProgressBarModule,
  ],
  providers: [
    FirebaseDynamicLinks,
    // {
    //   // provide: SETTINGS, useValue: {
    //   //   ignoreUndefinedProperties: true,
    //   //   // cacheSizeBytes: 10 * 1048576
    //   // }
    // },
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    { provide: ErrorHandler, useClass: MyErrorHandler },
    ScreenTrackingService, UserTrackingService,
    // { provide: CONFIG, useValue: {} },
    // { provide: APP_VERSION, useValue: environment },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
