import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'src/app/core/app.service';

@Component({
  selector: 'app-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.css']
})
export class BackComponent implements OnInit {

  @Input() title?: string;
  @Input() icon?: string;
  @Input() fallbackLink?: string;


  constructor(public app: AppService) { }

  ngOnInit(): void {
  }

}
