import { Injectable, ErrorHandler } from "@angular/core";
import { init, captureException } from '@sentry/browser';
import { environment } from 'src/environments/environment';


@Injectable()
export class MyErrorHandler implements ErrorHandler {
    logs: string;
    constructor() {
        // remoteConfig: AngularFireRemoteConfig
        // remoteConfig.strings.logs.subscribe(value => {
        //   this.logs = value;
        // });

        init({
            dsn: 'https://77e6e2982d8a47caa6b66220fe979cec@o365355.ingest.sentry.io/5019616',
            release: `v ${environment}`
        });

    }
    handleError(error) {

        // chunk loading failed handled
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
            return;
        }

        if (environment.production) {
            captureException(error.originalError || error);
        } else {
            console.error(error.originalError || error);
        }
    }
}
