// improved
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, BehaviorSubject } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { configureScope } from '@sentry/browser';
import { Firestore, doc, DocumentReference, docData, setDoc, collection, arrayUnion } from '@angular/fire/firestore';
import {
  Auth,
  user,
  signInWithCustomToken,
  updateProfile,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  updatePassword,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithCredential,
  PhoneAuthProvider,
  signInWithPhoneNumber,
  ApplicationVerifier,
} from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  myRef: DocumentReference<any>
  id: string;
  private subscriptions = new Subscription();
  loaded = new BehaviorSubject(false);
  userState = new BehaviorSubject(null);
  myState = new BehaviorSubject(null);

  constructor(
    private afAuth: Auth,
    private afs: Firestore,
    private router: Router,
    private snackBar: MatSnackBar,) {
    localStorage.getItem('gid') ? '' : localStorage.setItem('gid', doc(collection(this.afs, 'id')).id);

    this.subscriptions.add(
      user(this.afAuth).subscribe(user => {
        if (user) {
          this.myRef = doc(this.afs, `my/${user.uid}`);
          this.userState.next(user);
          // this.userState.next(user);
          configureScope(scope => {
            scope.setUser({
              username: user.phoneNumber,
              id: user.uid
            });
          });
          this.loaded.next(true);
          this.syncData();
        } else {
          this.id = localStorage.getItem('gid');
          this.myRef = doc(this.afs, `guests/${this.id}`);
          this.userState.next(null);
          configureScope(scope => scope.setUser(null));
          this.loaded.next(true);
        }
      })
    );

    this.subscriptions.add(
      this.userState.pipe(switchMap(user => {
        if (user) {

          this.id = user.uid;
          this.myRef = doc(this.afs, `my/${user.uid}`);
          return docData(this.myRef);
        } else {
          this.id = localStorage.getItem('gid');
          this.myRef = doc(this.afs, `guests/${this.id}`);
          return docData(this.myRef);
        }
      })).subscribe(item => {
        this.myState.next(item);
      })
    );

  }

  private syncData() {
    if (localStorage.getItem('gid')) {
      const gid = localStorage.getItem('gid');

      this.subscriptions.add(
        docData(doc(collection(this.afs, `guests/${gid}/shops`)), { idField: 'id' })
          .pipe(take(1))
          .subscribe(data => {
            if (data) {
              data.map((i) => {
                i.keyword = arrayUnion(...i.keyword);
                setDoc(doc(this.afs, `${this.myRef.path}/shops/${i.id}`), i, { merge: true });
              });
            }
          })
      );

      docData(doc(this.afs, `${this.myRef.path}/guests/${gid}`)).pipe(take(1))
        .subscribe((data: any) => {

          this.myState.pipe(take(1)).subscribe(profile => {
            if (!profile?.geo && data?.geo && data?.geoHash && data?.geoAddress) {
              setDoc(this.myRef, {
                geo: data.geo, geoHash: data.geoHash, geoAddress: data.geoAddress
              }, { merge: true });
            }
          });

        });
      localStorage.setItem('gid', doc(collection(this.afs, 'id')).id);

    }
  }


  signInWithCustomToken(token: string) {
    return signInWithCustomToken(this.afAuth, token);
  }

  updateProfile(displayName: string, photoURL: string) {
    return updateProfile(this.afAuth.currentUser,
      {
        displayName: displayName || 'Guest',
        photoURL: photoURL || 'assets/images/photo.jpg'
      }
    );
  }
  signInPhoneNumber(phoneNumber: string, appVerifier: ApplicationVerifier, returnUrl: string) {
    return signInWithPhoneNumber(this.afAuth, phoneNumber, appVerifier).then((confirmationResult) => {
      localStorage.setItem(
        'verificationId',
        JSON.stringify(confirmationResult.verificationId)
      );
      this.snackBar.open('Otp sent to your mobile number.', '', { duration: 3000 });
      // this.ngZone.run(() => {
      returnUrl ?
        this.router.navigateByUrl(returnUrl) :
        this.router.navigate(['/verify']);
      // });
    })
      .catch((error) => {
        console.log(error.message);
        this.snackBar.open(`error ${error.message}`, '', { duration: 3000 });
        alert(error.message);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 5000);
      });;
  }

  verifyOtp(verify: string, opt: string, returnUrl?: string) {
    var credential = PhoneAuthProvider.credential(
      verify,
      opt
    );

 return signInWithCredential(this.afAuth, credential)
      .then((response) => {
        this.userState.next(response.user);
        console.log(response);
        this.snackBar.open("Successfully login", '', { duration: 3000 });

        returnUrl ?
          this.router.navigateByUrl(returnUrl) : this.router.navigate(['/']);

      })
      .catch((error) => {
        console.log(error);
        alert(error.message);
        this.snackBar.open(error.message, '', { duration: 3000 });
      });

  }

  logOut() {
    this.afAuth.signOut().then(() => {
      this.router.navigate(['/']);
    });
  }
  emailLogin(email: string, password: string) {
    return signInWithEmailAndPassword(this.afAuth, email, password);
  }
  emailSignUp(email: string, password: string) {
    return createUserWithEmailAndPassword(this.afAuth, email, password);
  }

  forgetPassword(email: string) {
    return sendPasswordResetEmail(this.afAuth, email);
  }

  ChangePassword(newPassword: string) {
    return updatePassword(this.afAuth.currentUser, newPassword);

  }

  googleLogin() {
    return signInWithPopup(this.afAuth, new GoogleAuthProvider());
  }
}
