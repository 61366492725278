<div class="container">

  <app-back class="top"></app-back>

  <div class="content">
    <div class="base">
      <mat-card class="mat-elevation-z0">
        <mat-card-content>
          <ng-container>
            <mat-card-title>Verify your phone</mat-card-title>
          </ng-container>
          <p class="mute">A text message with a 6-digit verification code was just sent to
            <span matStepperPrevious color="primary">
              +{{dialCode}} {{phone}}</span>
          </p>

          <form name="form" #f="ngForm" class="form" (ngSubmit)="f.valid && verifyOtp(model)" novalidate>

            <mat-form-field class="form-field-width" appearance="outline">
              <mat-label>Enter the Code</mat-label>
              <input autocomplete="off" matInput type="number" required [(ngModel)]="model.otp" #otp="ngModel"
                [ngModelOptions]="{standalone: true}">
            </mat-form-field>

            <mat-spinner *ngIf="loading" color="primary" [diameter]="24"></mat-spinner>

            <div mat-dialog-actions>
              <button *ngIf="!loading" mat-raised-button class="btn-mobile" color="primary">Next</button>
            </div>
          </form>

        </mat-card-content>
      </mat-card>
    </div>
  </div>

</div>